<ibm-header [brand]="'Portfilio'">
    <ibm-header-navigation>
        <ibm-header-item [route]="[ '/home' ]">Public Frontend</ibm-header-item>
        <ibm-header-item [route]="[ '/cron' ]">CronJobs</ibm-header-item>
        <ibm-header-item [route]="[ '/projects' ]">Projekte</ibm-header-item>
    </ibm-header-navigation>
    <ibm-header-global>
        <ibm-header-action title="logout">
            <!--ibm-icon-logout size="16" size="20"></ibm-icon-logout-->
        </ibm-header-action>
    </ibm-header-global>
</ibm-header>