export class vita {
    name: string;
    text: String;
    tags: string[];

    constructor(name: string,text:string, tags: string[]) {
        this.name = name;        
        this.text = text;        
        this.tags = tags;
      }
}
