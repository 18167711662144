<header class="navbar">
    <img src="../../../assets/logo.png" alt="Logo"  [routerLink]="[ '/home']" style="width: 40px; height: 40px;margin-left: 25px;">
    <ul class="desktopNavBar">
        <li>
            <div class="NavBarItem" (click)="JumpToSection(1)">
                <h3>01.<span>Lebenslauf</span></h3>
            </div>
        </li>

        <li>
            <div class="NavBarItem" (click)="JumpToSection(2)">
                <h3>02. <span>Ausgewählte Projekte</span> </h3>
            </div>
        </li>
        <li>
            <div class="NavBarItem" (click)="JumpToSection(3)">
                <h3>03.<span>Portfolio</span> </h3>
            </div>
        </li>
        <li>
            <div class="NavBarItem" (click)="JumpToSection(4)">
                <h3>04. <span>Kontakt</span></h3>
            </div>
        </li>
    </ul>
    <div class="mobileMenu">
        <input type="checkbox" id="active" [(checked)]="menuActive" (click)="togglemenu()">
        <label for="active" class="menu-btn"><span></span></label>
        <label for="active" class="close"></label>
        <div class="wrapper">
            <ul>
                <li (click)="JumpToSection(1)">Lebenslauf</li>
                <li (click)="JumpToSection(2)">Projekte</li>
                <li (click)="JumpToSection(3)">Portfolio</li>
                <li (click)="JumpToSection(4)">Kontakt</li>
            </ul>
        </div>
    </div>

</header>

<div class="content">
    <div class="title">
        Fullscreen Overlay Navigation Bar</div>
    <p>
        (Hamburger Menu-2)</p>
</div>